import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Stock from './pages/Stock';
import Import_stock_replace from './pages/Import_stock_replace';
import Import_alarm from './pages/Import_alarm';
import Sellinghistory from './pages/Sellinghistory';

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Dashboard />}></Route>
          <Route path='/stock' element={<Stock />}></Route>
          <Route path='/importalarm' element={<Import_alarm />}></Route>
          <Route
            path='/importstockreplace'
            element={<Import_stock_replace />}
          ></Route>
          <Route path='/sellinghistory' element={<Sellinghistory />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
