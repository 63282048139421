import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Head from '../component/Head';
import SideHeader from '../component/SideHeader';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useMemo } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

function Sellinghistory() {
  const [data, setData] = useState('');

  // getdata
  const GetDataProduct = async () => {
    try {
      const response = await axios.get(
        'https://apiwarehouse.sidehustle.id/getselling'
      );
      setData(response.data.data);
      console.log(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    GetDataProduct();
  }, []);

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: 'id', //access nested data with dot notation
        header: 'ID',
        size: 50,
      },
      {
        accessorKey: 'tanggal', //access nested data with dot notation
        header: 'Tanggal',
        size: 50,
      },

      {
        accessorKey: 'kode_invoice', //access nested data with dot notation
        header: 'Kode Invoice',
        size: 50,
      },
      {
        accessorKey: 'asal', //access nested data with dot notation
        header: 'Asal',
        size: 50,
      },
      {
        accessorKey: 'jenis_produk', //access nested data with dot notation
        header: 'Jenis Produk',
        size: 50,
      },
      {
        accessorKey: 'code', //access nested data with dot notation
        header: 'Code',
        size: 50,
      },
      {
        accessorKey: 'article', //access nested data with dot notation
        header: 'Artikel',
        size: 50,
      },
      {
        accessorKey: 'size', //access nested data with dot notation
        header: 'Size',
        size: 50,
      },
      {
        accessorKey: 'qty', //access nested data with dot notation
        header: 'Qty',
        size: 50,
      },
      {
        accessorKey: 'media', //access nested data with dot notation
        header: 'Media',
        size: 50,
      },
      {
        accessorKey: 'admin', //access nested data with dot notation
        header: 'Admin',
        size: 50,
      },
      {
        accessorKey: 'toko', //access nested data with dot notation
        header: 'Toko',
        size: 50,
      },
      {
        accessorKey: 'jenis_pengiriman', //access nested data with dot notation
        header: 'Pengiriman',
        size: 50,
      },
      {
        accessorKey: 'ekspedisi', //access nested data with dot notation
        header: 'Ekspedisi',
        size: 50,
      },
      {
        accessorKey: 'penerima', //access nested data with dot notation
        header: 'Penerima',
        size: 50,
      },
      {
        accessorKey: 'note', //access nested data with dot notation
        header: 'Note',
        size: 50,
      },
      {
        accessorKey: 'file', //access nested data with dot notation
        header: 'Resi',
        size: 50,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: {
      sorting: [
        {
          id: 'id', //sort by age by default on page load
          desc: true,
        },
      ],
    }, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  });
  return (
    <div className='flex bg-slate-300'>
      <div className=''>
        <SideHeader />
      </div>
      <div className='bg-slate-300 p-10 w-5/6'>
        <Head namepage='Selling History' />
        <div className='mt-4 '>
          <div className='bg-white rounded-md shadow-md p-5'>
            <div className='block'>
              <div className='flex gap-3 mb-3'>
                <h1 className='text-3xl font-bold mr-5'>History</h1>
              </div>
            </div>
            <MaterialReactTable table={table} />
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default Sellinghistory;
