import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Head from '../component/Head';
import SideHeader from '../component/SideHeader';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useMemo } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

function Stock() {
  const [importshow, setimportShow] = useState(false);
  const importClose = () => setimportShow(false);
  const importShow = () => setimportShow(true);

  const [exportshow, setexportShow] = useState(false);
  const exportClose = () => setexportShow(false);
  const exportShow = () => setexportShow(true);

  const [data, setData] = useState('');

  // getdata
  const GetDataProduct = async () => {
    try {
      const response = await axios.get(
        'https://apiwarehouse.sidehustle.id/getproduct'
      );
      setData(response.data.data);
      console.log(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    GetDataProduct();
  }, []);

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: 'code', //access nested data with dot notation
        header: 'Code',
        size: 70,
      },
      {
        accessorKey: 'article', //access nested data with dot notation
        header: 'Article',
        size: 250,
      },
      {
        accessorKey: 'size', //access nested data with dot notation
        header: 'Size',
        size: 100,
      },
      {
        accessorKey: 'qty', //access nested data with dot notation
        header: 'Qty',
        size: 50,
      },
      {
        accessorKey: 'qty_alarm', //access nested data with dot notation
        header: 'Alarm',
        size: 50,
      },
      {
        accessorKey: 'alarm_status', //access nested data with dot notation
        header: 'Status Alarm',
        size: 50,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  });
  return (
    <div className='flex'>
      <div className=''>
        <SideHeader />
      </div>
      <div className='bg-slate-300 p-10 w-screen'>
        <Head namepage='Stock' />
        <div className='mt-4'>
          <div className='bg-white rounded-md shadow-md p-5'>
            <div className='block'>
              <div className='flex gap-3 mb-3'>
                <h1 className='text-3xl font-bold mr-5'>Warehouse Stock</h1>
                <Button variant='primary' disabled>
                  Editor
                </Button>
                <Button variant='primary' onClick={importShow}>
                  Import
                </Button>
                <Button variant='primary' onClick={exportShow}>
                  Export
                </Button>
              </div>
            </div>
            <MaterialReactTable table={table} />
          </div>
        </div>
        <div>
          <Modal show={importshow} onHide={importClose}>
            <div className='p-3'>
              <Modal.Header closeButton>
                <h1 className='text-2xl font-bold'>Konfirmasi Import Stock</h1>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <p className='leading-6'>
                    <b>Pilih 2 Mode ini :</b>
                    <br /> <b>1. Tambah Stock</b>
                    <br />
                    Opsi Tambah Stock digunakan saat touchdown
                    <br />
                    <br /> <b>2. Replace Stock</b>
                    <br />
                    Opsi Replace Stock digunakan saat Stock Opname Keseluruhan
                  </p>
                </div>
                <div className='flex gap-2 pt-2'>
                  <Button variant='primary' onClick={importClose}>
                    Tambah Stock
                  </Button>
                  <a href='/importstockreplace' className='no-underline'>
                    <Button variant='primary' onClick={importClose}>
                      Replace Stock (Hati-hati)
                    </Button>
                  </a>
                </div>
              </Modal.Body>
            </div>
          </Modal>
        </div>
        <div>
          <Modal show={exportshow} onHide={exportClose}>
            <div className='p-3'>
              <Modal.Header closeButton>
                <h1 className='text-2xl font-bold'>Konfirmasi Export Stock</h1>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <p className='leading-6'>
                    <b>Pilih 3 Mode ini :</b>
                    <br /> <b>1. Export Full </b>
                    <br />
                    (Download semua informasi stock dan alarm)
                    <br />
                    <br /> <b>2. Export Stock Only</b> <br />
                    (Download hanya info stock, untuk update stock)
                    <br />
                    <br /> <b>3. Export Alarm Only </b>
                    <br />
                    (Download hanya info alarm, untuk update alarm)
                    <br />
                  </p>
                </div>
                <div className='flex gap-2 pt-2'>
                  <a href='https://apiwarehouse.sidehustle.id/downloadproduct?type=full'>
                    <Button variant='primary' onClick={exportClose}>
                      Export Full
                    </Button>
                  </a>
                  <a href='https://apiwarehouse.sidehustle.id/downloadproduct?type=stock'>
                    <Button variant='primary' onClick={exportClose}>
                      Export Stock Only
                    </Button>
                  </a>
                  <a href='https://apiwarehouse.sidehustle.id/downloadproduct?type=alarm'>
                    <Button variant='primary' onClick={exportClose}>
                      Export Alarm Only
                    </Button>
                  </a>
                </div>
              </Modal.Body>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default Stock;
