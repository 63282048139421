import React from 'react';
import { Link } from 'react-router-dom';

function SideHeader() {
  return (
    <div>
      <div className='sticky top-0 f-c1 min-h-screen flex items-center pl-6 pr-6 hide-on-mobile bg-white'>
        <div className=''>
          <div className='ml'>
            <h1 className='text-black text-left mt-5 font-bold text-3xl'>
              WAREHOUSE
              <br /> DASHBOARD
            </h1>
          </div>

          <div className='mt-5 flex flex-col'>
            <Link to='/' className='no-underline'>
              <button class='text-black text-left font-bold py-3 flex '>
                <img
                  src='icon/icon_dashboard.svg'
                  className='w-10 pr-4 my-auto'
                />
                DASHBOARD
              </button>
            </Link>
            <Link to='/stock' className='no-underline'>
              <button class='text-black text-left font-bold py-3 flex'>
                <img src='icon/icon_stock.svg' className='w-10 pr-5 my-auto' />
                STOCK
              </button>{' '}
            </Link>
            <Link to='/importalarm' className='no-underline'>
              <button class='text-black text-left font-bold py-3 flex'>
                <img src='icon/icon_alarm.svg' className='w-10 pr-4 my-auto' />
                ALARM
              </button>
            </Link>
            <Link to='/sellinghistory' className='no-underline'>
              <button class='text-black text-left font-bold py-3 flex'>
                <img
                  src='icon/icon_sellinghistory.svg'
                  className='w-10 pr-4 my-auto'
                />
                SELLING HISTORY
              </button>
            </Link>
            <button class='text-black text-left font-bold py-3 flex'>
              <img
                src='icon/icon_touchdown.svg'
                className='w-10 pr-4 my-auto'
              />
              TOUCHDOWN HISTORY
            </button>
            <button class='text-black text-left font-bold py-3 flex'>
              <img src='icon/icon_scan.svg' className='w-10 pr-4 my-auto' />
              BARCODE SCANNER
            </button>
          </div>
          <div className='flex gap-3 mt-5 m-0 items-center'>
            <a href='/'>
              <img src='icons/icon_mail_wht.png' alt='' className='w-7' href />
            </a>
            <a href='/'>
              <img src='icons/icon_instagram_wht.png' alt='' className='w-6' />
            </a>
            <a href='/'>
              <img src='icons/icon_linkedin_wht.png' alt='' className='w-6' />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideHeader;
