import { React, useState } from 'react';
import axios from 'axios';
import Head from '../component/Head';
import SideHeader from '../component/SideHeader';

function Import_stock_replace() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      setErrorMessage('Please select a file.');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await axios.post(
        'https://apiwarehouse.sidehustle.id/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log(response);
      setUploadSuccess(true);
    } catch (error) {
      console.error('File upload failed:', error);
      setErrorMessage('File upload failed. Please try again.');
    }
  };

  return (
    <div className='flex'>
      <div className=''>
        <SideHeader />
      </div>
      <div className='bg-slate-300 p-5 w-screen'>
        <Head namepage='Stock Replace' />
        <div className='grid grid-cols-4 gap-4 mt-4 '>
          <div className='bg-white rounded-md shadow-md p-4 col-span-2'>
            <div className='block '>
              <div className='flex place-content-between  mb-2'>
                <h1 className='text-2xl font-bold'>Upload Excel</h1>
                <button>
                  <img src='icon/three-dots-vertical.svg' alt='my image' />
                </button>
              </div>
              <div>
                <p>
                  Hati - hati semua perubahan data saat melakukan stock replace,
                  tidak bisa di kembalikan. Pastikan sudah mendownload backup
                  data terlebih dahulu
                </p>
                <img
                  src='icon/icon_upload_files.svg'
                  alt='my image'
                  className='h-auto w-30'
                />

                <div className='mt-3 flex'>
                  <label
                    htmlFor='file-upload'
                    className='px-3 py-2 bg-blue-500 text-white rounded-md cursor-pointer'
                  >
                    Pilih File Excel
                  </label>
                  <input
                    id='file-upload'
                    type='file'
                    accept='.xlsx'
                    className='hidden'
                    onChange={handleFileSelect}
                  />
                  {selectedFile && (
                    <p className='my-auto ml-3 text-gray-700 font-medium'>
                      Selected File: {selectedFile.name}
                    </p>
                  )}
                </div>
                <div>
                  <button
                    onClick={handleFileUpload}
                    className='mt-4 px-4 py-2 bg-green-500 text-white rounded-md cursor-pointer'
                  >
                    Upload
                  </button>
                  {uploadSuccess && (
                    <p className='mt-2 text-green-600'>
                      File uploaded successfully!
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='grid grid-cols-2 gap-4 mt-4'></div>
      </div>
    </div>
  );
}

export default Import_stock_replace;
