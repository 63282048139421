import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';

function Head({ namepage }) {
  const [StatusResponse, setStatusResponse] = useState('');
  const [SystemStatus, setSystemStatus] = useState(0);
  const getSystemStatus = async () => {
    try {
      const response = await axios.get(
        'https://apiwarehouse.sidehustle.id/sysstatus'
      );
      setSystemStatus(response.data.SystemStatus);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const toggle_sysstatus = async () => {
    try {
      const response = await axios.post(
        'https://apiwarehouse.sidehustle.id/toggle_sysstatus'
      );
      setStatusResponse(response.data.status);
      getSystemStatus();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getSystemStatus();
  }, []);

  return (
    <div className='flex space-x-6'>
      <div className='bg-white flex p-4 rounded-md shadow-md'>
        <h1 className='font-bold text-4xl'>{namepage}</h1>
      </div>
      <div className='bg-white flex p-4 rounded-md shadow-md space-x-4'>
        {SystemStatus === 1 ? (
          <h1 className='font-bold text-4xl text-green-500'>Online</h1>
        ) : (
          <h1 className='font-bold text-4xl text-red-600'>Offline</h1>
        )}
        <Button variant='primary' onClick={() => toggle_sysstatus()}>
          Switch
        </Button>
        <p className='my-auto text-green-500 font-semibold'>{StatusResponse}</p>
      </div>
    </div>
  );
}

export default Head;
