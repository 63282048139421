import React, { useState, useEffect } from 'react';
import Head from '../component/Head';
import SideHeader from '../component/SideHeader';
import axios from 'axios';

function Dashboard() {
  const [bestproduct, setBestproduct] = useState(['']);

  const [alarmproduct, setAlarmproduct] = useState(['']);
  const [Capacity, setCapacity] = useState([
    {
      capacity: 1,
      category: 'Lempong',
      id_category: 1,
      total_qty: 1,
    },
  ]);

  // getdata
  const getbestproduct = async () => {
    try {
      const response = await axios.get(
        'https://apiwarehouse.sidehustle.id/getarticlemonth?days=30'
      );
      setBestproduct(response.data.data.sort((a, b) => b.qty - a.qty));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getPercentage = (x, y) => {
    if (y === 0) return 'Division by zero'; // Menghindari pembagian dengan nol
    return ((x / y) * 100).toFixed(0);
  };

  // getcapacity
  const getcapacity = async () => {
    try {
      const response = await axios.get(
        'https://apiwarehouse.sidehustle.id/getcapacity'
      );
      setCapacity(response.data.data);
      console.log(Capacity);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // getalarm
  const getalarm = async () => {
    try {
      const response = await axios.get(
        'https://apiwarehouse.sidehustle.id/getalarm?count=20'
      );
      setAlarmproduct(response.data.data.sort((a, b) => a.selisih - b.selisih));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    getbestproduct();
    getalarm();
    getcapacity();
  }, []);
  return (
    <div className='flex'>
      <div className=''>
        <SideHeader />
      </div>
      <div className='bg-slate-300 p-5 w-screen'>
        <Head namepage='Dashboard' />
        <div className='flex gap-4 mt-4'>
          <div className='bg-white rounded-md shadow-md p-4'>
            <div className='block'>
              <div className='mb-2'>
                <div className='flex place-content-between mb-2'>
                  <h1 className='text-2xl font-bold'>Stock Summary</h1>
                  <button>
                    <img src='icon/three-dots-vertical.svg' alt='my image1' />
                  </button>
                </div>
                <div>
                  <div className='flex space-x-6'>
                    {Capacity.map((item, index) => (
                      <div key={index} className='flex items-center'>
                        <img
                          src='icon/icon_1.svg'
                          alt=''
                          className='h-auto w-14 my-auto'
                        />
                        <div className='block pl-4'>
                          <h1 className='font-bold text-xl my-0 py-0'>
                            [{item.id_category}] Gudang {item.category}
                          </h1>
                          <p className='font-bold my-0 py-0'>
                            {getPercentage(item.total_qty, item.capacity)}% of
                            Usage
                          </p>
                          <p className='font-bold my-0 py-0'>
                            {item.total_qty}/{item.capacity}
                          </p>
                        </div>
                        {index !== Capacity.length - 1 && ( // Menambahkan garis vertikal kecuali untuk elemen terakhir
                          <div className='border-2 border-blue-400 h-20 ml-5'></div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='grid grid-cols-2 gap-4 mt-4'>
          <div className='bg-white rounded-md shadow-md p-4'>
            <div className='block'>
              <div className='flex place-content-between  mb-4'>
                <h1 className='text-2xl font-bold'>Best Products (30 Days)</h1>
                <button>
                  <a href='https://apiwarehouse.sidehustle.id/downloadbestproduct?days=30'>
                    <button>
                      <img
                        src='icon/icon_downloads.svg'
                        alt='my image3'
                        className='h-auto w-4 my-auto'
                      />
                    </button>
                  </a>
                </button>
              </div>
              <div>
                {bestproduct.length > 1 ? (
                  bestproduct.slice(0, 30).map((artikel) => (
                    <div className='grid grid-cols-5 gap-4 pb-2 pt-2 border-b-2 border-slate-300'>
                      <div className='ml-1 col-span-4 flex my-auto'>
                        <img
                          src='icon/icon_2.svg'
                          alt=''
                          className='mr-3 h-auto w-10 my-auto'
                        />
                        <h1 className='my-auto text-base text-left'>
                          {artikel.article}
                        </h1>
                      </div>
                      <div className='ml-1 col-span-1'>
                        <h1 className='text-base text-right'>
                          {artikel.qty} Qty
                        </h1>
                      </div>
                    </div>
                  ))
                ) : (
                  <h1>Loading</h1>
                )}
              </div>
            </div>
          </div>
          <div className='bg-white rounded-md shadow-md p-4'>
            <div className='block'>
              <div className='flex place-content-between  mb-4'>
                <h1 className='text-2xl font-bold'>
                  Products Alarm (20 Product)
                </h1>
                <a href='https://apiwarehouse.sidehustle.id/downloadalarm'>
                  <button>
                    <img
                      src='icon/icon_downloads.svg'
                      alt='my image3'
                      className='h-auto w-4 my-auto'
                    />
                  </button>
                </a>
              </div>
              <div>
                {bestproduct.length > 1 ? (
                  alarmproduct.map((artikel) => (
                    <div className='grid grid-cols-5 gap-4 pb-2 pt-2 border-b-2 border-slate-300'>
                      <div className='ml-1 col-span-4 flex my-auto'>
                        <img
                          src='icon/icon_3.svg'
                          alt=''
                          className='mr-3 h-auto w-10 my-auto'
                        />
                        <h1 className='my-auto text-base text-left'>
                          {artikel.article}
                        </h1>
                      </div>
                      <div className='ml-1 col-span-1'>
                        <h1 className='my-auto text-base text-right'>
                          {artikel.qty} / {artikel.qty_alarm}
                        </h1>
                      </div>
                    </div>
                  ))
                ) : (
                  <h1>Loading</h1>
                )}
              </div>
              {}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
